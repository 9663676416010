.job-info {
    display: flex;
    padding: 10px 0 0;

    @include mobileOnly {
        flex-flow: column;
    }

    &__title {
        margin-bottom: 10px;
        @include mobileOnly {
            line-height: 45px;
        }
    }

    &__highlights {
        margin: 20px 0 30px;
        flex: 0 0 auto;
        @include mobileOnly {
            margin-bottom: 15px;
        }
    }

    &__recruiter {
        flex: 0.4;
        @include ieOnly {
            flex: 1 1 25%;
        }
    }
    &__job-text {
        @include ieOnly {
            flex: 1 1 65%;
        }
    }
    &__recruiter-basic {
        margin-top: 20px;
        overflow: hidden;
        @include flex();
    }
    &__recruiter-main {
        justify-content: space-between;
    }
    &__recruiter-name {
        font-size: 18px;
        font-weight: 500;
    }
    &__recruiter-headline {
        font-size: 16px;
        margin-top: 10px;
    }
    &__recruiter-description {
        font-size: 18px;
        line-height: 26px;
        margin-top: 20px;
        @include mobileOnly {
            font-size: 16px;
        }
    }
    &__recruiter-img-wrapper {
        @include size(104px, 104px);
        min-width: 104px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
    }
    &__recruiter-img {
        @include square(100%);
        object-fit: cover;
    }

    &__vSeparator {
        background-color:  $separator;
        width: 1px;
        margin: 0 30px;

        @include mobileOnly() {
            height: 1px;
            width: initial;
            margin: 20px 0 30px;
        }
    }
}