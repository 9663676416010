.ai-modal {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    .long {
        @include flex();
        min-height: 600px;
    }
    .confirmation-modal__content {
        height: 80vh;
    }
}
.admin-jobs-list {
    margin-top: 10px;
    
    &__active {
        font-size: 20px;
        &.fa-check-circle {
            color: $blue;
        }
        &.fa-times-circle {
            color: $pink;
        }
        &.fa-eye-slash {
            color: $pink;
        }
    }

    .ReactTable {
        .title-column-header {
            justify-content: flex-start;
            padding: 0 18px;
        }
        .rt-tr-group {
            .rt-tr, .rt-td {
                padding-bottom: 0;
            }
            &:not(:hover) {
                .title-column__actionsBar {
                    visibility: hidden;
                }
            }
        }
    }
    .title-column {
        text-align: left;
        @include rtl {
            text-align: right;
        }
        padding: 0 10px 5px;
        &__title {
            font-weight: bold;
            margin: 2px 0;
            &:hover {
                color: $blue;
            }
        }
        &__actionsBar {
            font-size: 14px;
            color: $pink;
            margin-top: 5px;
            a:hover {
                color: inherit;
                text-decoration: underline;
            }
        }
        .job-highlight {
            margin: 5px 15px 0 0;
            @include rtl {
                text-align: right;
                margin: 5px 0 0 15px;
            }
        }
    }
    &__actionButton {
        min-width: unset;
        font-size: 20px;
        padding: 0;
        margin: 2px;
        a {
            height: 100%;
            display: inline-block;
        }
        i {
            height: 100%;
            display: inline-block;
            padding: 0 7px;

        }
    }
    &__modalButton {
        min-width: unset;
        padding: 0 5px 0 5px;
        margin: 2px;
    }
}